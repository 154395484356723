import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { ParagraphWrapper } from '../ParagraphWrapper';

const processBody = html => {
  const body = html.replace(/[\n\r]/g, '');

  // Extract script from body
  const scriptRegExp = /<script>(.*)<\/script>/m;
  const scriptMatches = body.match(scriptRegExp);
  let htmlBody = body;
  let script;
  if (scriptMatches && scriptMatches[1]) {
    htmlBody = body.replace(scriptRegExp, '');
    script = scriptMatches[1];
  }

  return { htmlBody, script };
};

const ContentHtmlLegacyInner = ({ data }) => {
  if (data.body) {
    const tagRef = useRef(null);
    const processedBody = processBody(data.body);
    const innerHTML = processedBody.htmlBody;

    useEffect(() => {
      if (processedBody.script) {
        const scriptElement = document.createElement('script');
        const inlineScript = document.createTextNode(processedBody.script);
        scriptElement.appendChild(inlineScript);
        tagRef.current.appendChild(scriptElement);
      }
    });

    return (
      <div
        ref={tagRef}
        // eslint-disable-next-line
        dangerouslySetInnerHTML={{ __html: innerHTML }}
      />
    );
  }
  return null;
};

ContentHtmlLegacyInner.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired
};

export const ContentHtmlLegacy = ParagraphWrapper(ContentHtmlLegacyInner);
