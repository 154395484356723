import { useState, useEffect } from 'react';
import { conf } from '../domain/services/configService';
import { apiClientService } from '../domain/services/apiClientService';

export const useGeoLocation = (filter, countries, deps) => {
  const [currentCountry, setCurrentCountry] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const url = `https://${conf.canonicalDomain}/geoip/country`;
      const { response } = await apiClientService.getJson(url);
      setCurrentCountry(response.country.iso_code);
    };

    if (filter) {
      fetchData();
    }
  }, [deps]);

  let isVisible = !filter;

  if (filter && currentCountry) {
    if (filter === 'allow') {
      isVisible = countries.some(
        country => country.entity.isoCode.value === currentCountry
      );
    } else if (filter === 'disallow') {
      isVisible = !countries.some(
        country => country.entity.isoCode.value === currentCountry
      );
    }
  }

  return isVisible;
};
